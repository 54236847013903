<template>
  <div class="fragment">
    <div class="custom-row" v-if="isAdmin">
      <div class="custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['returnGoods_shipped'])"></div>
        <DatePickerDefault
            :label="$t('returnGoods_shipped.localization_value.value')"
            v-bind:class="{'ui-no-valid': RG.validation.shippedDate}"
            :errorTxt="$t(`${RG.validationTranslate.shippedDate}.localization_value.value`)"
            :error="RG.validation.shippedDate"
            v-model="RG.data.shippedDate"
        >
          <template slot="body">
            <date-picker
                v-model="RG.data.shippedDate"
                ref="date"
                valueType="format"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'YYYY-MM-DD'"
                :placeholder="'YYYY-MM-DD'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="custom-col custom-col--50 custom-col--md-100" v-if="isAdmin">
        <UserSelect
            :validationUser="RG.data.User.validation.userId"
            :validationTranslateUser="RG.data.User.validationTranslate.userId"
            :userSelected="RG.data.User.user"
            :typeSelect="'users'"
            :userEmail="true"
            :label="'common_user'"
            :forSearchType="true"
            :disabled="isObject(RG.data.trackingNumberObject) && RG.data.trackingNumberObject.tracking_number"
            @changeUser="changeUser"
        />
      </div>
    </div>


    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--md-100 position-relative"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'returnGoods_search',
          ])"></div>

        <div class="copy-name">
          <LinkButton
              :value="''"
              :type="'copy'"
              @click.native="copyTracking"
          />
        </div>
        <SearchSelect
            :options="options"
            :label="$t('returnGoods_search.localization_value.value')"
            v-bind:class="{'ui-no-valid': RG.validation.trackingNumberObject}"
            :errorTxt="$t(`${RG.validationTranslate.trackingNumberObject}.localization_value.value`)"
            :error="RG.validation.trackingNumberObject"
            :functionSearch="(value, loading) => functionSearch(value, loading, 'trackingNumberOrIdFBM')"
            :typeSelect="'fbmTrackNumberData'"
            :selected="RG.data.trackingNumberObject"
            @change="changeFoundTrackNumber"
        />
      </div>

      <div class="custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['returnGoods_trackingNumber'])"></div>
        <div class="section-label">
          <DefaultInput
              :label="$t('returnGoods_trackingNumber.localization_value.value')"
              :type="'text'"
              v-bind:class="{'ui-no-valid': RG.validation.trackingNumber}"
              :errorTxt="$t(`${RG.validationTranslate.trackingNumber}.localization_value.value`)"
              :error="RG.validation.trackingNumber"
              v-model="RG.data.trackingNumber"
          />
<!--              :disabled="isObject(RG.data.trackingNumberObject)"-->

        </div>
      </div>

      <div class="custom-col custom-col--50 custom-col--md-100"
           v-if="RG.data.orderTrackingNumber !== ''"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['returnGoods_orderTrackingNumber'])"></div>
        <div class="section-label">
          <DefaultInput
              :label="$t('returnGoods_orderTrackingNumber.localization_value.value')"
              :type="'text'"
              :disabled="true"
              v-model="RG.data.orderTrackingNumber"
          />
        </div>
      </div>
    </div>


    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['returnGoods_comment', 'returnGoods_commentDefault'])"></div>
        <TextareaDefault
            :label="$t('returnGoods_comment.localization_value.value')"
            v-bind:class="{'ui-no-valid': RG.validation.comment}"
            :errorTxt="$t(`${RG.validationTranslate.comment}.localization_value.value`)"
            :error="RG.validation.comment"
            v-model="RG.data.comment"
        />
<!--            :placeholder="'Please be careful with the vase inside!'"-->
      </div>

      <div class="order-create__col custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['returnGoods_comment', 'returnGoods_commentDefault'])"></div>
        <DefaultSelect
            class="w-100"
            :optionsLabel="'name'"
            :options="optionsStorage"
            :label="$t('productsReport_Storage.localization_value.value')"
            :selected="RG.data.storage"
            @change="changeStorage"
        />
      </div>
    </div>

    <div class="section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['returnGoods_documentsUp',])"></div>
      {{ $t('returnGoods_documentsUp.localization_value.value') }}
    </div>

    <div class="custom-row" :key="images - 100">
      <div class="custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
           v-for="(item, index) in RG.data.Files.data.downloadFiles"
           :key="index">
        <div class="site-document d-flex justify-content-center">
              <span class="site-document__remove" @click="removeDocument(item)">
                <CloseIcon/>
              </span>
          <div class="site-document__img">
            <img v-if="item.mime_type !== 'application/pdf'"
                 :src="`data:${item.type};base64,` + item.base64"
                 @click="showSingle(item.id + 1)"
                 alt="img"
            >
            <img v-else
                 @click="openPdf(item['base64'])"
                 style="width: 30px; height: 40px;"
                 src="/img/common/pdf_file_icon.svg"
                 alt="ico"
            >
          </div>
        </div>
      </div>

      <div class="custom-col position-relative" v-if="RG.data.Files.data.maxCountFiles > RG.data.Files.data.downloadFiles.length">
        <DropZone class="drop-zone-bg"
                  :parentFiles="RG.data.Files.data.files"
                  :multiple="true"
                  :maxCount="RG.data.Files.data.maxCountFiles - RG.data.Files.data.downloadFiles.length"
                  :maxSize="RG.data.Files.data.maxSizeFiles"
                  :accept="'image/x-png,image/gif,image/jpeg,application/pdf'"
                  :captureFlag="''"
                  @changeImg="changeImg"
        />
        <span class="error-field ml-3" v-if="RG.data.Files.validation.files">
          {{$t('common_fileIsRequired.localization_value.value')}}
        </span>
      </div>
    </div>

    <Lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
    ></Lightbox>

  </div>
</template>

<script>
import DatePickerDefault from "@/components/UI/inputs/DatePickerDefault/DatePickerDefault";
import DatePicker from 'vue2-datepicker'
import UserSelect from "@/components/coreComponents/UserSelect/UserSelect";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import TextareaDefault from "@/components/UI/textarea/TextareaDefault/TextareaDefault";
import SearchSelect from "@/components/UI/selectiones/SearchSelect/SearchSelect";
import DropZone from "@/components/coreComponents/DropZone/DropZone";
import Lightbox from "vue-easy-lightbox";
import CloseIcon from '../../../../../../../public/img/modal-group/close-icon.svg?inline'
import {filterFunc} from "@/mixins/filterFuncMixin/filterFunc";
import {returnGoodsMixin} from "@/mixins/returnGoodsMixins/returnGoodsMixin";
import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

export default {
  name: "FieldsBlock",
  components: {
    DefaultSelect,
    LinkButton,
    DropZone,
    SearchSelect,
    TextareaDefault,
    DefaultInput,
    UserSelect,
    DatePickerDefault,
    DatePicker,
    Lightbox,
    CloseIcon,
  },

  mixins: [filterFunc, returnGoodsMixin],

  props: {
    RG: Object,
    optionsStorage: Array,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      options: [],
      showTransactionInfo: false,
      images: -1,
      imgs: [], // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
    }
  },

  methods: {

    changeFoundTrackNumber(val) {
      this.RG.data.trackingNumberObject = val
      // this.RG.data.trackingNumber = val.tracking_number;
      if(val){
        this.RG.data.changeTrackingNumberObject = true
        this.RG.data.User.setUser(val.user)
      } else {
        if(!this.isEdit) {
          this.RG.data.User.setUser('')
        }
      }
    },

    changeUser(val){
      this.RG.data.User.setUser(val)
    },

    changeStorage(val) {
      this.RG.setStorage(val)
    },

    handleHide() {
      this.visible = false
    },

    changeImg(files, maxSizeError) {
      this.RG.data.Files.setFiles(files)

      this.RG.data.Files.setFilesMaxSizeError(maxSizeError)
    },

    copyTracking() {
      if(this.RG.data.trackingNumberObject?.tracking_number) {
        this.copyToClipboard(this.RG.data.trackingNumberObject?.tracking_number)
      }

      if(this.RG.data.trackingNumberObject?.custom_tracking_number) {
        this.copyToClipboard(this.RG.data.trackingNumberObject?.custom_tracking_number)
      }
    },

    getFilteredTrackingNumberOrIdFBM(value, that, optionName, loading) {
      if(this.transactionFilterTimeoutTime !== undefined) {
        let dateNow = new Date()
        if(dateNow - this.transactionFilterTimeoutTime < 500){
          clearTimeout(this.transactionFilterTimeout)
          createTimeOut()
        }
      } else {
        createTimeOut()
      }

      function createTimeOut() {
        that.transactionFilterTimeoutTime = new Date()
        that.transactionFilterTimeout = setTimeout(() => {
          search()
        },500)
      }

      function search() {
        that.transactionFilterTimeoutTime = undefined

        if(value.length === 0) {
          that[optionName] = []
        }
        if(value.length < 1) {
          loading(false)
          return
        }
        console.log(333);

        const query = new that.Query();
        let url = '?';
        let myQuery = query
            .for('posts')
        myQuery.where('byIdOrTracking', value)

        url = url + myQuery.limit(100000).page(1).url().split('?')[1]
        that.$store.dispatch('fetchOrdersFBM', {filter: url}).then(response => {
          console.log(response.data.data.data.length);
          if(response.data.data.data.length > 0) {
            that[optionName] = response.data.data.data
          } else {
            // that[optionName] = [{custom_tracking_number: value}]
            that.RG.data.trackingNumberObject = {custom_tracking_number: value}
            that.RG.data.changeTrackingNumberObject = true
          }

          loading(false)
        })
      }
    },
  },

}
</script>

<style scoped>
  .copy-name {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -17px;
  }
</style>